import React, { useEffect, useState } from "react";
import cl from "./Favorites.module.css";
import SearchResult from "../Search/SearchResult";
import axios from "axios";
import api from "../../api";
import useGlobalUserState from "../../stores/user";
import { useNavigation } from "react-router-dom";
import { FavoriteProfileLink } from "./FavoriteProfileLink";
import { FavoriteCard } from "./FavoriteCard";
import { Icon } from "../../components/Icon/Icon";

// listing,
// firstImage = false,
// mode = "listing",
// parent = "Search",
// disabled = false,
// additionalClassName = "",
// showCalc = true,

const imgurl =
  "https://s3-alpha-sig.figma.com/img/04ad/8e08/5a75aeb055704f1992e5140597a60af7?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=I~jJw05q5JwYBf-lHXmN1YbReCjrHDIoB2-37tdqTk10JE4am-5a9pCa9XEBjbPojIfgGlak6W1075r8kGUQ3p2-WhHkdfxxZhBClMSRK9aEb9hjD3-tTCwdH8jC11tyZMDKGz~2P4ywy-Mt1jcz8CGJLyvwOocaHPGiidfKCQ9wX9bLnBu387hNoV7iTzXcG-OrQCHW5cfB6NgVFj~HLMpiwB1-yxqHAVMDA4ILky4~v3xSgxbhMxQZ1~pS-VpFY-S8X9XMlc1yCkS1BTVzeMtK83ccjhWI-JdSRXtmzhdawseDDsIytalWaYQr5c8ILm6RBkRnOvSyWUlEC0L1Nw__";

export const Favorites = () => {
  const [listings, setListings] = useState(null);
  const [isSortMenuOpen, setIsSortMenuOpen] = useState(false);
  const [sortType, setSortType] = useState({
    value: "date_added",
    title: "По дате добавления",
  });
  const user = useGlobalUserState().get();

  const loadData = async (sort = {}) => {
    const req = await axios.get(
      `${api.serverUrl}/api/v1/users/get-favorites/${user.id}?sort=${sort.value}`
    );
    setListings(req.data.favorites);
  };

  useEffect(() => {
    if (!user.phone) {
      // navigation.navigate("/");
    } else {
      loadData();
    }
  }, []);

  const sorts = [
    {
      value: "date_added",
      title: "По дате добавления",
    },
    {
      value: "date_published",
      title: "По дате публикации",
    },
    {
      value: "price",
      title: "По цене",
    },
  ];

  const links = [
    {
      icon: "partners",
      title: "Личная информация",
      href: "/personal",
    },
    {
      icon: "blog",
      title: "Блог",
      href: "/blog",
    },
    {
      icon: "bell",
      title: "Уведомления",
      href: "/my-notifications",
    },

    {
      icon: "listings",
      title: "Мои объявления",
      href: "/my-listings",
    },
    {
      icon: "contracts",
      title: "Мои договоры",
      href: "/my-contracts",
    },
    {
      icon: "contracts",
      title: "Мои бронирования",
      href: "/my-rent-bookings",
    },
  ];

  const settingLinks = [
    {
      title: "Политика конфиденциальности",
      href: "https://kiltapp.kz/html/privacy-policy.html",
    },
    {
      title: "Правила сайта",
      href: "https://kiltapp.kz/uploads/rules.pdf",
    },
    {
      title: "Связаться с нами",
      href: "/contact-us",
    },
    {
      icon: "logout",
      title: "Выход",
      href: "/balance",
    },
  ];

  return (
    <div className='xl:container flex flex-col px-4 sm:px-0'>
      <div className={cl.wrapper}>
        <div className={cl.left}>
          <div className={cl.profile}>
            <div className={cl.pfp}>
              <img src={imgurl} alt='dsa' />
            </div>
            <div className={cl.profileTexts}>
              <div className={cl.name}>
                {user?.firstname} {user?.lastname}
              </div>
              <div className={cl.position}>
                {user?.user_type === "owner" && "Владелец"}
                {user?.user_type === "specialist" && "Агент по недвижимости"}
                {user?.user_type === "agency" && "Агентство недвижимости"}
              </div>
            </div>
          </div>
          <div className={cl.balanceCard}>
            <div className={cl.balanceLeft}>
              <div className={cl.balanceCardLeftTitle}>Баланс:</div>
              <div className={cl.balanceCardPrice}>{user.bonus}</div>
            </div>
            <div className={cl.right}>
              <div className={cl.balanceCardRightTitle}>Пополнить баланс:</div>
            </div>
          </div>
          <div className={cl.links}>
            {links.map((link) => (
              <FavoriteProfileLink
                title={link.title}
                icon={link?.icon}
                href={link?.href}
              />
            ))}
          </div>
          <div className={cl.settingsTitle}>О проекте</div>
          <div className={cl.links}>
            {settingLinks.map((link) => (
              <FavoriteProfileLink
                title={link.title}
                icon={link?.icon}
                href={link?.href}
              />
            ))}
          </div>
        </div>
        <div className={cl.right}>
          <div className={cl.rightTitle}>Избранное</div>
          <div
            className={cl.sort}
            onClick={() => {
              setIsSortMenuOpen(!isSortMenuOpen);
            }}
          >
            <Icon name='sort-down' />
            {sortType.title}
          </div>
          {isSortMenuOpen && (
            <div className={cl.sortMenu}>
              {sorts.map((sort) => (
                <div
                  className={cl.sortMenuItem}
                  onClick={() => {
                    setSortType(sort);
                    setIsSortMenuOpen(false);
                    loadData(sort);
                  }}
                >
                  {sort.title}
                </div>
              ))}
            </div>
          )}
          <div className={cl.listings}>
            {listings?.map((listing) => (
              <SearchResult listing={listing} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
