import React, { useEffect, useState } from "react";
import ProfileList from "../../components/List/ProfileList";
import Modal from "../../components/Modal/Modal";
import SearchResult from "../Search/SearchResult";
import Listing from "../Listing/Listing";
import { ModerationActions } from "./ModerationActions";
import { useGlobalMiscState } from "../../stores/misc";
import { useListing } from "../../hooks/listing";
import cl from "./Partner.module.css";
import {
  dealTypes,
  addDealTypes,
  listingTypes,
  property_type,
} from "../../constants";
import useGlobalUserState from "../../stores/user";
import api from "../../api";
import axios from "axios";
import utils from "../../utils";
import GradientButton from "../../components/Buttons/GradientButton";

export const Partners = ({}) => {
  const miscState = useGlobalMiscState();
  const profileListFetchTrigger = miscState.get("profileListFetchTrigger");
  const userState = useGlobalUserState();
  const user = userState.get("user");
  const [moderationModalOpen, setModerationModalOpen] = useState(false);
  const [createNewPartner, setCreateNewPartner] = useState(false);
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [newPartnerData, setNewPartnerData] = useState({});
  const [previewLogoImage, setPreviewLogoImage] = useState(null);
  const [previewCoverImage, setPreviewCoverImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const opeModerationModal = (id) => {
    if (id === null) {
      setCreateNewPartner(true);
      setModerationModalOpen(true);
      return;
    }
    setCreateNewPartner(false);
    setModerationModalOpen(true);
    setNewPartnerData(id);
  };
  const onCloseNew = () => {
    setCreateNewPartner(false);
  };

  const onClose = () => {
    setModerationModalOpen(false);
    setNewData({});
    setPreviewLogoImage(null);
    setPreviewCoverImage(null);
  };

  useEffect(() => {
    if (!moderationModalOpen) {
      miscState.set("profileListFetchTrigger", profileListFetchTrigger + 1);
    }
  }, [moderationModalOpen]);

  const loadData = async () => {
    const req = await api.partnersApi.getAll();
    setPartners(req.data.partners);
  };

  const setNewData = (key, value) => {
    setNewPartnerData({
      ...newPartnerData,
      [key]: value,
    });
  };

  const handleIconImageChange = (e) => {
    setNewData("new_icon_image", e.target.files[0]);
    setPreviewLogoImage(URL.createObjectURL(e.target.files[0]));
  };

  const handleCoverImageChange = (e) => {
    setNewData("new_cover_image", e.target.files[0]);
    setPreviewCoverImage(URL.createObjectURL(e.target.files[0]));
  };

  const submitForm = async () => {
    setIsLoading(true);
    if (createNewPartner === true) {
      const formdata = new FormData();
      formdata.append("name", newPartnerData.name);
      formdata.append("category", newPartnerData.category);
      formdata.append("tags", newPartnerData.tags);
      formdata.append("phone", newPartnerData.phone);
      formdata.append("address", newPartnerData.address);
      formdata.append("website", newPartnerData.website);
      formdata.append("description", newPartnerData.description);
      formdata.append("icon_image", newPartnerData.new_icon_image);
      formdata.append("cover_image", newPartnerData.new_cover_image);
      const req = await axios.post(
        `${api.serverUrl}/api/v1/partner/create`,
        formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(req);
      setModerationModalOpen(false);
      setNewPartnerData({});
      utils.showSnackbar("Партнер создан", "success");
      setIsLoading(false);
      return;
    }
    const formdata = new FormData();
    formdata.append("id", newPartnerData.id);
    formdata.append("name", newPartnerData.name);
    formdata.append("category", newPartnerData.category);
    formdata.append("tags", newPartnerData.tags);
    formdata.append("phone", newPartnerData.phone);
    formdata.append("address", newPartnerData.address);
    formdata.append("website", newPartnerData.website);
    formdata.append("description", newPartnerData.description);
    formdata.append("icon_image", newPartnerData.new_icon_image);
    formdata.append("cover_image", newPartnerData.new_cover_image);
    const req = await axios.post(
      `${api.serverUrl}/api/v1/partner/update`,
      formdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(req);
    setIsLoading(false);
    setModerationModalOpen(false);
    setNewPartnerData({});
    utils.showSnackbar("Партнер обновлен", "success");
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <React.Fragment>
      <div className={cl.partnerCard} onClick={() => opeModerationModal(null)}>
        <div className={cl.imagePlaceholder}>+</div>
        <div className={cl.partnerCardText}>
          <div className={cl.partnerName}>Добавить партнера</div>
        </div>
      </div>
      {partners.map((partner) => (
        <div
          className={cl.partnerCard}
          onClick={() => {
            opeModerationModal(partner);
          }}
          key={partner.id}
        >
          <div className={cl.partnerCardIcon}>
            <img
              src={`${api.serverUrl}/uploads/${partner.icon_image}`}
              alt=''
              className={cl.cardImg}
            />
          </div>
          <div className={cl.partnerCardText}>
            <div className={cl.partnerName}>{partner?.name}</div>
            <div className={cl.partnerCardSubtitle}>{partner?.category}</div>
          </div>
        </div>
      ))}

      <Modal
        dimensions='w-full h-full xl:w-4/5 xl:h-[80%] overflow-y-scroll rounded-md'
        visible={moderationModalOpen}
        setVisible={setModerationModalOpen}
      >
        <div className={cl.wrapper}>
          <div className='flex justify-between items-center'>
            <h1 className='text-2xl font-semibold'>Изменение партнера</h1>
            <button onClick={onClose}>X</button>
          </div>

          <div className={cl.form}>
            <div className={cl.FileView}>
              <div className={cl.inputFileLabel}>
                <label className={cl.labelfile}>
                  <input
                    className={cl.inputFile}
                    type='file'
                    onChange={handleIconImageChange}
                  />
                  Добавить логотип партнера{" "}
                  <img
                    src='https://www.svgrepo.com/show/28557/upload-sign.svg'
                    alt=''
                    className={cl.imgUpload}
                  />
                </label>
                <img
                  // src={`${api.serverUrl}/uploads/${newPartnerData.icon_image}`}
                  src={
                    previewLogoImage !== null
                      ? previewLogoImage
                      : `${api.serverUrl}/uploads/${newPartnerData.icon_image}`
                  }
                  alt=''
                  className={cl.img}
                />
              </div>

              <div className={cl.inputFileLabel}>
                <label className={cl.labelfile}>
                  <input
                    className={cl.inputFile}
                    type='file'
                    onChange={handleCoverImageChange}
                  />
                  Добавить фон страницы партнера
                  <img
                    src='https://www.svgrepo.com/show/28557/upload-sign.svg'
                    alt=''
                    className={cl.imgUpload}
                  />
                </label>
                <img
                  src={
                    previewCoverImage
                      ? previewCoverImage
                      : `${api.serverUrl}/uploads/${newPartnerData.cover_image}`
                  }
                  alt=''
                  className={cl.img}
                />
              </div>
            </div>

            <div className={cl.inputView}>
              <div className={cl.input}>
                <label>Название</label>
                <input
                  className={cl.inputText}
                  type='text'
                  placeholder='Имя партнера'
                  value={newPartnerData.name}
                  onChange={(e) => setNewData("name", e.target.value)}
                />
              </div>
            </div>

            <div className={cl.inputView}>
              <div className={cl.input}>
                <label>Категория</label>
                <input
                  className={cl.inputText}
                  type='text'
                  placeholder='Категория партнера'
                  value={newPartnerData.category}
                  onChange={(e) => setNewData("category", e.target.value)}
                />
              </div>
            </div>

            <div className={cl.inputView}>
              <div className={cl.input}>
                <label>
                  Теги (через запятую, н. "кэшбэк от 5%,смартфоны,смартфоны и
                  девайсы")
                </label>
                <input
                  className={cl.inputText}
                  type='text'
                  placeholder='кэшбэк от 5%,смартфоны,смартфоны и девайсы'
                  value={newPartnerData.tags}
                  onChange={(e) => setNewData("tags", e.target.value)}
                />
              </div>
            </div>

            <div className={cl.inputView}>
              <div className={cl.input}>
                <label>Номер телефона</label>
                <input
                  className={cl.inputText}
                  type='text'
                  placeholder='8707 123 45 67'
                  value={newPartnerData.phone}
                  onChange={(e) => setNewData("phone", e.target.value)}
                />
              </div>
            </div>

            <div className={cl.inputView}>
              <div className={cl.input}>
                <label>Адрес</label>
                <input
                  className={cl.inputText}
                  type='text'
                  placeholder='Абая 123, офис 12'
                  value={newPartnerData.address}
                  onChange={(e) => setNewData("address", e.target.value)}
                />
              </div>
            </div>

            <div className={cl.inputView}>
              <div className={cl.input}>
                <label>Веб-сайт</label>
                <input
                  className={cl.inputText}
                  type='text'
                  placeholder='https://example.com/'
                  value={newPartnerData.website}
                  onChange={(e) => setNewData("website", e.target.value)}
                />
              </div>
            </div>

            <div className={cl.inputView}>
              <div className={cl.input}>
                <label>Описание</label>
                <input
                  className={cl.inputText}
                  type='text'
                  placeholder='Описание'
                  value={newPartnerData.description}
                  onChange={(e) => setNewData("description", e.target.value)}
                />
              </div>
            </div>
          </div>
          <GradientButton
            onClick={submitForm}
            text='Сохранить'
            additionalClass={cl.btn}
            loading={isLoading}
            hasLoading={true}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};
