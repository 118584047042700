import React, { useEffect } from "react";
import cl from "./AgencyModeration.module.css";
import Modal from "../../components/Modal/Modal";
import api from "../../api";
import axios from "axios";
import useGlobalConfigState, {
  accessGlobalConfigState,
} from "../../stores/config";

export const ProfileEditModeration = () => {
  const [profiles, setProfiles] = React.useState([]);
  const [selectedProfile, setSelectedProfile] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [showMessageModal, setShowMessageModal] = React.useState(false);
  const configState = useGlobalConfigState();
  const config = configState.get();
  const advancedConfig = config.advanced || {};
  const loadData = async () => {
    const data = await axios.get(
      `${api.serverUrl}/api/v1/users/get-moderation-profile-data`
    );
    setProfiles(data.data.data);
  };

  const onApprove = async () => {
    const req = await axios.post(
      `${api.serverUrl}/api/v1/users/moderation-approve-profile`,
      {
        id: selectedProfile?.id,
      }
    );
    setShowModal(false);
    setSelectedProfile(null);
    loadData();
  };

  const onDecline = async () => {
    const req = await axios.post(
      `${api.serverUrl}/api/v1/users/moderation-decline-profile`,
      {
        id: selectedProfile?.id,
      }
    );

    setShowModal(false);
    setSelectedProfile(null);
    loadData();
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <React.Fragment>
        {profiles?.length === 0 && (
          <div className={cl.noData}>Нет данных для модерации</div>
        )}
        {profiles.map((profile) => (
          <div
            className={cl.partnerCard}
            onClick={() => {
              setShowModal(true);
              setSelectedProfile(profile);
            }}
            key={profile?.id}
          >
            <div className={cl.partnerCardIcon}>
              <img
                src={`${api.serverUrl}/uploads/${profile?.user?.photo}`}
                alt=''
                className={cl.cardImg}
              />
            </div>
            <div className={cl.partnerCardText}>
              <div className={cl.partnerName}>
                {profile?.user?.firstname}{" "}
                {profile?.user?.lastname ? profile?.user?.lastname : ""}
              </div>
              <div className={cl.partnerCardSubtitle}>
                {profile?.user?.user_type === "agent" && "Агент"}
                {profile?.user?.user_type === "agency" && "Агентство"}
                {profile?.user?.user_type === "owner" && "Владелец"}
              </div>
            </div>
          </div>
        ))}
        <Modal
          dimensions='w-full h-full xl:w-4/5 xl:h-[80%] overflow-y-scroll rounded-md'
          visible={showModal}
          setVisible={setShowModal}
        >
          <div className={cl.modalWrapper}>
            {selectedProfile?.type === "user_update" && (
              <div className={cl.dataModal}>
                <div className={cl.dataLeft}>
                  <div className={cl.titleModal}>Старые данные</div>
                  <div className={cl.data}>
                    {Object.keys(selectedProfile?.data?.oldData).map((key) => (
                      <div key={key} className={cl.dataItem}>
                        <div className={cl.key}>
                          {
                            JSON.parse(
                              advancedConfig?.moderation_custom_labels
                            )[key]
                          }
                        </div>
                        <div className={cl.value}>
                          {selectedProfile?.data?.oldData[key]}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={cl.dataLeft}>
                  <div className={cl.titleModal}>Новые данные</div>
                  <div className={cl.data}>
                    {Object.keys(selectedProfile?.data?.newData).map((key) => (
                      <div key={key} className={cl.dataItem}>
                        <div className={cl.key}>
                          {
                            JSON.parse(
                              advancedConfig?.moderation_custom_labels
                            )[key]
                          }
                        </div>
                        <div className={cl.value}>
                          {selectedProfile?.data?.newData[key]}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {selectedProfile?.type === "user_update_profile_image" && (
              <div className={cl.photoModal}>
                <div className={cl.left}>
                  <div className='p'>Старое фото</div>
                  <img
                    src={`${api.serverUrl}/uploads/${selectedProfile?.data?.oldData?.photo}`}
                    alt=''
                  />
                </div>
                <div className={cl.right}>
                  <div className='p'>Новое фото</div>
                  <img
                    src={`${api.serverUrl}/uploads/${selectedProfile?.data?.newData?.photo}`}
                    alt=''
                  />
                </div>
              </div>
            )}
            <div className={cl.wrapper}>
              <div className={cl.buttons}>
                <button
                  title='Принять'
                  className={cl.btnYes}
                  onClick={onApprove}
                >
                  Можно
                </button>
                <button
                  title='Отклонить'
                  onClick={onDecline}
                  className={cl.btnNo}
                >
                  Нельзя
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    </div>
  );
};
