import { Suspense, useEffect, useRef, useState } from "react";
import { Map2Gis } from "../../components/Map2Gis/Map2Gis";

export const AddListingMap = ({}) => {
  const [message, setMessage] = useState(null);
  const [center, setCenter] = useState(null)  ;

  const onMapClick = async (data) => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        event: "clickMap",
        data: JSON.stringify(data),
      })
    );
  }

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === "changeCenter") {
        setCenter(JSON.parse(JSON.stringify(event.data.data)));
      }
    };

    window.addEventListener("messageFromReactNative", handleMessage);
    return () => {
      window.removeEventListener("messageFromReactNative", handleMessage);
    };
  }, []);

  return (
    <Map2Gis
      results={!!message ? message : []}
      onMapDragEnd={()=>{}}
      onClusterClick={()=>{}}
      center={center}
      onCLick={onMapClick}
    />
  );
};
