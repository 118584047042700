import React, { useEffect, useState } from "react";
import { Clusterer } from "@2gis/mapgl-clusterer";
import { MapContext } from "../../context/MapContext";

import { load } from "@2gis/mapgl";
const MapWrapper = React.memo(
  () => {
    return (
      <div id='map-container' style={{ width: "100%", height: "100%" }}></div>
    );
  },
  () => true
);
export const Map2Gis = ({ results, onMapDragEnd, onClusterClick, center, onCLick=false }) => {
  const [_, setMapInstance] = React.useContext(MapContext);
  const [mapInstance] = React.useContext(MapContext);
  const [clusterer, setClustere] = useState(null);
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    if (!!mapInstance) {
      markerHandlers();
    }
  }, [mapInstance]);

  useEffect(() => {
    if (mapInstance && center !== null) {
      mapInstance.setCenter(center);
      mapInstance.setZoom(12);
    }
  }, [center]);

  useEffect(() => {
    let map;
    load().then((mapglAPI) => {
      map = new mapglAPI.Map("map-container", {
        center: [76.89625, 43.237639],
        zoom: 12,
        key: "35c20748-a280-4583-b8e2-cc4d1eebed62",
      });

  const marker = new mapglAPI.Marker(map, {
          coordinates: [0,0]
        })

      setMapInstance(map);
      map.on("moveend", (e) => {
        onMapDragEnd(JSON.stringify(map.getBounds()));
      });
      map.on("click", (e)=>{
        if(onCLick){
          onCLick(e.lngLat)
          marker.setCoordinates(e.lngLat);
        }
      })
      map.on("zoomend", () => {
        onMapDragEnd(JSON.stringify(map.getBounds()));
      });
      onMapDragEnd(JSON.stringify(map.getBounds()));
      markerHandlers(map);
    });

    return () => {
      map && map.destroy();
      clusterer && clusterer.destroy();
    };
  }, []);

  const markerHandlers = (map = mapInstance, newRes=false) => {
    clusterer?.destroy();
    if (!!map & !!results) {
      let newMarkers = results.map((i) => {
        return {
          coordinates: [i?.lng, i?.lat],
          size: [36, 36],
          icon: "https://docs.2gis.com/img/mapgl/marker.svg",
          id: i.id,
        };
      });
      if(newRes){
        clusterer?.destroy();
        newMarkers = [{
          coordinates: newRes,
          size: [36,36],
          icon: "https://docs.2gis.com/img/mapgl/marker.svg",
        }]
      }
      const newCluster = new Clusterer(map, {
        radius: 140,
        clusterStyle: (count) => {
          return {
            type: "html",
            html: `<div style="border-radius: 50%; aspect-ratio : 1 / 1; border:4px solid #3F4FE0; background: #fff; display: flex; text-align: center; min-width: 33px; min-height: 33px; justify-content: center; align-items: center; padding: 6px" >${count}</div>`,
            labelFontSize: 16,
            labelColor: "#000",
          };
        },
      });
      newCluster.load(newMarkers);
      newCluster.on("click", (event) => {
        if (event.target.type === "cluster") {
          onClusterClick(event.target.data.map((i) => i.id));
        } else {
          // alert(JSON.stringify(event.target.data));
          onClusterClick([event.target.data.id]);
        }
      });
      setClustere(newCluster);
    }
  };

  useEffect(() => {
    markerHandlers();
  }, [results]);

  return (
    <div className='map'>
      <MapWrapper />
    </div>
  );
};
