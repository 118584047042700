import React, { useEffect, useState } from "react";
import ProfileList from "../../components/List/ProfileList";
import Modal from "../../components/Modal/Modal";
import SearchResult from "../Search/SearchResult";
import Listing from "../Listing/Listing";
import { ModerationActions } from "./ModerationActions";
import { useGlobalMiscState } from "../../stores/misc";
import { useListing } from "../../hooks/listing";
import cl from "./Partner.module.css";
import {
  dealTypes,
  addDealTypes,
  listingTypes,
  property_type,
} from "../../constants";
import useGlobalUserState from "../../stores/user";
import api from "../../api";
import axios from "axios";
import utils from "../../utils";
import GradientButton from "../../components/Buttons/GradientButton";
import BorderedButton from "../../components/Buttons/BorderedButton";

export const PartnerItems = ({}) => {
  const [partners, setPartners] = useState([]);
  const [selectPartnerModal, setSelectPartnerModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [previewLogoImage, setPreviewLogoImage] = useState(null);
  const [newProductData, setNewProductData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [editProductModal, setEditProductModal] = useState(false);

  const submitForm = async () => {
    const formData = new FormData();
    formData.append("name", newProductData.name);
    formData.append("image", newProductData.image);
    formData.append("partner_id", selectedPartner);
    if (newProductData.id) {
      formData.append("id", newProductData.id);
    }
    const req = await axios.post(
      `${api.serverUrl}/api/v1/partner/update-product`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    loadProducts();
    setEditProductModal(false);
    setNewProductData({});
  };

  const deleteProduct = async (id) => {
    const req = await axios.post(
      `${api.serverUrl}/api/v1/partner/delete-product`,
      { id }
    );
    setEditProductModal(false);
    loadProducts();
  };

  const setNewData = (key, value) => {
    setNewProductData({
      ...newProductData,
      [key]: value,
    });
  };

  const openSelectPartnerModal = () => {
    setSelectPartnerModal(true);
  };

  const onClose = () => {
    setEditProductModal(false);
    setSelectPartnerModal(false);
  };

  const editProductHandler = (product) => {
    setNewProductData(product);
    setEditProductModal(true);
    setSelectPartnerModal(false);
  };

  const handleNewImage = (e) => {
    setNewData("image", e.target.files[0]);
    setPreviewLogoImage(URL.createObjectURL(e.target.files[0]));
  };

  const loadData = async () => {
    setIsLoading(true);
    const req = await api.partnersApi.getAll();
    setPartners(req.data.partners);
    setIsLoading(false);
  };

  const loadProducts = async () => {
    const req = await api.partnersApi.getProducts(selectedPartner);
    if (req) {
      setProducts(req.data.products);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!!selectPartnerModal) {
      loadProducts();
    }
  }, [selectedPartner]);

  return (
    <React.Fragment>
      <div className={cl.partnerCard} onClick={() => openSelectPartnerModal()}>
        <div className={cl.imagePlaceholder}>/</div>
        <div className={cl.partnerCardText}>
          <div className={cl.partnerName}>Выбрать партнера</div>
        </div>
      </div>
      <Modal
        dimensions='w-full h-full xl:w-4/5 xl:h-[80%] overflow-y-scroll rounded-md'
        visible={selectPartnerModal}
        setVisible={setSelectPartnerModal}
      >
        <div className={cl.wrapper}>
          <div className={cl.selectView}>
            Партнер
            <select
              className={cl.selectInput}
              onChange={(e) => {
                setSelectedPartner(e.target.value);
              }}
              value={selectedPartner}
            >
              <option value=''>Выберите партнера</option>
              {partners.map((partner) => (
                <option key={partner.id} value={partner.id}>
                  {partner.name}
                </option>
              ))}
            </select>
          </div>
          <div className={cl.products}>
            {selectedPartner && (
              <div
                className={cl.productCard}
                onClick={() => {
                  editProductHandler(null);
                }}
              >
                <div className={cl.imagePlaceholder}>+</div>
                <div className='productTitle'>Добавить продукт</div>
              </div>
            )}
            {selectedPartner &&
              products.map((product) => (
                <div
                  className={cl.productCard}
                  key={`${product.id}-${product?.name}`}
                  onClick={() => {
                    editProductHandler(product);
                  }}
                >
                  <div className={cl.cardImage}>
                    <img
                      src={`${api.serverUrl}/uploads/${product.image}`}
                      // src={`https://kiltapp.kz/uploads/${product?.image}`}
                      alt=''
                      className={cl.cardImg}
                    />
                  </div>
                  <div className='productTitle'>{product?.name}</div>
                </div>
              ))}
          </div>
        </div>
      </Modal>
      <Modal
        dimensions='w-full h-full xl:w-4/5 xl:h-[80%] overflow-y-scroll rounded-md'
        visible={editProductModal}
        setVisible={setEditProductModal}
      >
        <div className={cl.wrapper}>
          <div className='flex justify-between items-center'>
            <h1 className='text-2xl font-semibold'>
              Изменение продукта {newProductData?.name}
            </h1>
            <button onClick={onClose}>X</button>
          </div>

          <div className={cl.form}>
            <div className={cl.FileView}>
              <div className={cl.inputFileLabel}>
                <label className={cl.labelfile}>
                  <input
                    className={cl.inputFile}
                    type='file'
                    onChange={handleNewImage}
                  />
                  Добавить картинку
                  <img
                    src='https://www.svgrepo.com/show/28557/upload-sign.svg'
                    alt=''
                    className={cl.imgUpload}
                  />
                </label>
                <img
                  // src={`${api.serverUrl}/uploads/${newPartnerData.icon_image}`}
                  src={
                    previewLogoImage !== null
                      ? previewLogoImage
                      : `${api.serverUrl}/uploads/${newProductData?.image}`
                    // `https://kiltapp.kz/uploads/${newProductData?.image}`
                  }
                  alt=''
                  className={cl.img}
                />
              </div>
            </div>

            <div className={cl.inputView}>
              <div className={cl.input}>
                <label>Название</label>
                <input
                  className={cl.inputText}
                  type='text'
                  placeholder='Стиральная машина'
                  value={newProductData?.name}
                  onChange={(e) => setNewData("name", e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className={cl.btnGroup}>
            <GradientButton
              onClick={submitForm}
              text='Сохранить'
              additionalClass={cl.btn}
              loading={isLoading}
              hasLoading={true}
            />
            {newProductData?.id && (
              <BorderedButton
                onClick={() => deleteProduct(newProductData.id)}
                additionalClass={cl.btn}
                loading={isLoading}
                hasLoading={true}
                text={<div className={cl.btnText}>Удалить</div>}
              ></BorderedButton>
            )}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
