import React from "react";
import cl from "./Favorites.module.css";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../components/Icon/Icon";

export const FavoriteProfileLink = ({ icon, title, href }) => {
  const navigate = useNavigate();
  return (
    <div
      className={cl.linkView}
      onClick={() => {
        if (href.startsWith("http")) {
          window.location.href = href;
        } else {
          navigate("/profile" + href);
        }
      }}
    >
      <div className={cl.linkLeft}>
        <div className={cl.icon}>
          <Icon name={icon} />
        </div>
        <div className={cl.title}>{title}</div>
      </div>
      <div className={cl.chevron}>{">"}</div>
    </div>
  );
};
