import React from "react";

import {
  blog,
  partners,
  docs,
  listings,
  contracts,
  bell,
  logout,
  favorite,
  favoriteGray,
  sortDown,
} from "./IconRaw";

const icons = {
  blog: blog(),
  partners: partners(),
  docs: docs(),
  listings: listings(),
  contracts: contracts(),
  bell: bell(),
  logout: logout(),
  favorite: favorite(),
  "favorite-gray": favoriteGray(),
  "sort-down": sortDown(),
};

export const Icon = ({ name, fill }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {icons[name]}
    </div>
  );
};
